body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}

h1 {
    margin: unset;
}

html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root {
    width: 100%;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-bar {
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex: 0 0 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.footer-bar {
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex: 0 0 50px;
}

.icon {
    width: 20px;
    padding: 10px;
}

button {
    padding: .75em;
    border: none;
    border-radius: 10px;
    background: #6480a3;
    font-size: 1em;
    color: white;
    outline: none;
    cursor: pointer;
}

button:hover {
    background: #95aac5;
}

.bar-wrapper {
    display: flex;
    justify-content: center;
}

.top-shadow {
    box-shadow: 0 15px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
}

.footer-bar {
    box-shadow: 0 -15px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.bar-wrapper > * {
    max-width: 1200px;
    width: 100%;
    flex: 1 0 auto;
    padding: 10px 20px;
    box-sizing: border-box;
}